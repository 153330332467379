<template>
  <div
    v-if="editor"
    class="text-editor"
    :class="{ 'is-editing': editing }"
    @click="editor.commands.focus()"
  >
    <div v-if="editing" class="editor-controls">
      <div class="control-group">
        <el-select
          :value="editor.isActive('heading') ? editor.getAttributes('heading').level : 0"
          size="small"
          @input="setSelectedHeading"
        >
          <el-option
            v-for="heading in headings"
            :key="heading.value"
            :label="heading.label"
            :value="heading.value"
          />
        </el-select>
      </div>
      <div class="control-group">
        <el-tooltip content="Bold">
          <button
            :class="{ 'is-active': editor.isActive('bold') }"
            @click="editor.chain().focus().toggleBold().run()"
          >
            <bold-icon />
          </button>
        </el-tooltip>
        <el-tooltip content="Italic">
          <button
            :class="{ 'is-active': editor.isActive('italic') }"
            @click="editor.chain().focus().toggleItalic().run()"
          >
            <italic-icon />
          </button>
        </el-tooltip>
        <el-tooltip content="Strikethrough">
          <button
            :class="{ 'is-active': editor.isActive('strike') }"
            @click="editor.chain().focus().toggleStrike().run()"
          >
            <strike-icon />
          </button>
        </el-tooltip>
      </div>
      <div class="control-group">
        <el-tooltip content="Bullet List">
          <button
            :class="{ 'is-active': editor.isActive('bulletList') }"
            @click="editor.chain().focus().toggleBulletList().run()"
          >
            <bullet-list-icon />
          </button>
        </el-tooltip>
        <el-tooltip content="Numbered List">
          <button
            :class="{ 'is-active': editor.isActive('orderedList') }"
            @click="editor.chain().focus().toggleOrderedList().run()"
          >
            <number-list-icon />
          </button>
        </el-tooltip>
      </div>
      <div class="control-group">
        <el-popover
          v-model="linkMenuOpen"
          placement="bottom"
          width="300"
          trigger="manual"
        >
          <div class="text-editor-link-menu">
            <el-input
              v-model="linkUrl"
              placeholder="Link"
              type="url"
              prefix-icon="el-icon-link"
            />
            <el-input
              v-model="linkText"
              placeholder="Text"
              prefix-icon="el-icon-edit"
              :disabled="!editor.state.selection.empty"
            />
            <el-row type="flex" justify="end">
              <el-button @click="linkMenuOpen = false">
                Cancel
              </el-button>
              <el-button
                type="primary"
                @click="insertLink"
              >
                Apply
              </el-button>
            </el-row>
          </div>
          <el-tooltip slot="reference" content="Link">
            <button
              :class="{ 'is-active': linkMenuOpen }"
              @click="linkMenuOpen = !linkMenuOpen"
            >
              <link-icon />
            </button>
          </el-tooltip>
        </el-popover>
        <el-tooltip content="Code">
          <button
            :class="{ 'is-active': editor.isActive('codeBlock')}"
            @click="editor.chain().focus().toggleCodeBlock().run()"
          >
            <code-icon />
          </button>
        </el-tooltip>
        <el-tooltip content="Quote">
          <button
            :class="{ 'is-active': editor.isActive('blockquote') }"
            @click="editor.chain().focus().toggleBlockquote().run()"
          >
            <quote-icon />
          </button>
        </el-tooltip>
        <el-tooltip content="Horizontal Rule">
          <button
            @click="editor.chain().focus().setHorizontalRule().run()"
          >
            <horizontal-rule-icon />
          </button>
        </el-tooltip>
      </div>
      <div class="control-group">
        <el-tooltip content="Undo">
          <button
            :disabled="!editor.can().undo()"
            @click="editor.chain().focus().undo().run()"
          >
            <undo-icon />
          </button>
        </el-tooltip>
        <el-tooltip content="Redo">
          <button
            :disabled="!editor.can().redo()"
            @click="editor.chain().focus().redo().run()"
          >
            <redo-icon />
          </button>
        </el-tooltip>
      </div>
    </div>
    <editor-content :editor="editor" />
  </div>
</template>

<script>
/**
 * Wrapper component for TipTap Editor
 * https://tiptap.dev/
 */
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'

import BoldIcon from '@/assets/icons/svg/format_bold.svg'
import ItalicIcon from '@/assets/icons/svg/format_italic.svg'
import StrikeIcon from '@/assets/icons/svg/strikethrough_s.svg'
import BulletListIcon from '@/assets/icons/svg/format_list_bulleted.svg'
import NumberListIcon from '@/assets/icons/svg/format_list_numbered.svg'
import HorizontalRuleIcon from '@/assets/icons/svg/horizontal_rule.svg'
import LinkIcon from '@/assets/icons/svg/link.svg'
import CodeIcon from '@/assets/icons/svg/code.svg'
import QuoteIcon from '@/assets/icons/svg/format_quote.svg'
import UndoIcon from '@/assets/icons/svg/undo.svg'
import RedoIcon from '@/assets/icons/svg/redo.svg'

import regex from '@/utils/regex'

export default {
  components: {
    EditorContent,
    BoldIcon,
    ItalicIcon,
    StrikeIcon,
    BulletListIcon,
    NumberListIcon,
    HorizontalRuleIcon,
    LinkIcon,
    CodeIcon,
    QuoteIcon,
    UndoIcon,
    RedoIcon
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    editing: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      editor: null,
      headings: [
        { value: 0, label: 'Normal text' },
        { value: 1, label: 'Heading 1' },
        { value: 2, label: 'Heading 2' },
        { value: 3, label: 'Heading 3' },
        { value: 4, label: 'Heading 4' },
        { value: 5, label: 'Heading 5' },
        { value: 6, label: 'Heading 6' }
      ],
      linkMenuOpen: false,
      linkUrl: '',
      linkText: ''
    }
  },
  computed: {
    currentSelection () {
      return this.editor && this.editor.state.selection
    }
  },
  watch: {
    value (value) {
      const isSame = this.editor.getHTML() === value

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
    editing (value) {
      this.editor.setEditable(value)
    },
    /**
     * Watch the currently selected text and sets
     * the link menu fields accordingly.
     */
    currentSelection (value) {
      const { from, to } = value
      this.linkText = this.editor.state.doc.textBetween(from, to, ' ')
      this.linkUrl = this.editor.isActive('link') ? this.editor.getAttributes('link').href : ''
    }
  },
  mounted () {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Link.configure({
          openOnClick: false
        })
      ],
      editable: this.editing,
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML())
      }
    })
  },
  beforeDestroy () {
    this.editor.destroy()
  },
  methods: {
    /**
     * Sets headings based on the selected value from the dropdown.
     *
     * @param {Number} selected - heading dropdown values
     */
    setSelectedHeading (selected) {
      if (selected === 0) {
        const selectedLevel = this.editor.getAttributes('heading').level
        return this.editor.chain().focus().toggleHeading({ level: selectedLevel }).run()
      }
      return this.editor.chain().focus().setHeading({ level: selected }).run()
    },
    /**
     * Handler for when the 'apply' button from the link menu is clicked.
     * Inserts/removes/replaces a link in the editor.
     */
    insertLink () {
      const { from, empty } = this.editor.state.selection

      // validate URL
      if (this.linkUrl && !regex.url.test(this.linkUrl)) {
        this.$message({
          message: 'Please enter a valid URL',
          type: 'error',
          duration: 2000
        })
        return
      }

      // if no currently selected text
      if (empty) {
        if (!this.linkUrl) {
          this.$message({
            message: 'Please enter a valid URL',
            type: 'error',
            duration: 2000
          })
          return
        }
        // create new text with link
        const text = this.linkText || this.linkUrl
        this.editor
          .chain()
          .focus()
          .insertContent(text)
          .setTextSelection({ from: from, to: from + text.length })
          .setLink({ href: this.linkUrl, target: '_blank' })
          .setTextSelection(from + text.length)
          .run()
      } else {
        // if currently selected text is already a link and url field is empty, remove the link
        if (this.editor.isActive('link') && !this.linkUrl) {
          this.editor.commands.unsetLink()
        } else if (this.linkUrl) {
          this.editor.commands.setLink({ href: this.linkUrl, target: '_blank' })
        }
      }
      this.linkMenuOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
$primary: #1a7dc6;
$text-color: #606266;
$text-disabled-color: #bbb;
$border-color: #dcdfe6;

.text-editor {
  &.is-editing {
    border: 1px solid $border-color;
    border-radius: 4px;
    padding: 0.5em;
  }

  .editor-controls {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .control-group {
      display: inherit;
      border-left: 2px solid $border-color;
      padding: 0 0.5em;

      &:first-of-type {
        border-left: none;
        padding-left: 0;
      }
    }

    button {
      background-color: transparent;
      outline: none;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      border-radius: 4px;

      svg {
        fill: $text-color;
      }

      &:hover {
        background-color: rgba(0,0,0,0.1);
      }

      &.is-active {
        background-color: rgba($primary, 0.2);
        svg {
          fill: $primary;
        }
      }

      &:disabled {
        svg {
          fill: $text-disabled-color;
        }
      }
    }
  }

  ::v-deep .ProseMirror {
    outline: none;
    h1 {
      font-size: 1.75em;
    }
    pre {
      background: #2e2e2e;
      color: #fff;
      font-family: monospace;
      padding: 0.75em 1em;
      border-radius: 0.5em;

      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }
    }
    blockquote {
      margin-left: 0;
      margin-right: 0;
      padding-left: 1em;
      border-left: 3px solid rgba(#000, 0.1);
      color: rgba(#000, 0.5)
    }
    hr {
      border: 0.25px solid transparent;
      background-color: rgba(#000, 0.1);
    }
  }
}
</style>

<style>
.text-editor-link-menu .el-input {
  margin-bottom: 1em;
}
</style>
